import * as React from 'react';
import { isArray } from 'lodash';
import { useRoutes } from '@core/hooks';
import { isBrowser } from '@core/utils/env';
import Spinner from '@components/Spinner';
import NotFoundPage from '@pages/404';
import { RouteComponentProps } from '@reach/router';
import { GlobalProps } from '@interface/common';
import * as styles from './index.module.scss';


type Child = React.ReactElement<any, string | React.JSXElementConstructor<any>>;

type Props = Pick<RouteComponentProps, 'path' | 'location'> & GlobalProps & {
  children: Child | Child[];
}

export default function CheckoutResultLayout({ location, isAuthenticating, children }: Props) {
  const { routes } = useRoutes();

  if (!isBrowser) {
    return <Spinner/>;
  }

  if (isBrowser && !routes.checkoutResult.includes(location!.pathname)) {
    return <NotFoundPage/>;
  }

  if (isAuthenticating) {
    return <Spinner className={styles.spinner}/>;
  }

  return !isArray(children) ? React.cloneElement(children, { component: React.Fragment }) : null;
}
