import * as React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import * as api from '@core/api';
import { useCart, useEntities, useLibrary, useRoutes } from '@core/hooks';
import useCheckout, { CheckoutLocationState } from '@core/hooks/useCheckout';
import Main from '@components/Main';
import Wrapper from '@components/Wrapper';
import Centerer from '@components/Centerer';
import Content from '@components/Content';
import Message from '@components/Message';
import Info from '@components/Info';
import Link from '@components/Link';
import CartStepsIndicator from '@components/cart/CartStepsIndicator';
import LibraryExplorer from '@components/library/LibraryExplorer';
import { RouteComponentProps } from '@reach/router';
import { PostgresData, Product } from '@interface/gatsby';
import { API, GlobalProps } from '@interface/common';
import * as styles from './checkout_success.module.scss';


type Props = RouteComponentProps<{ location: { state: CheckoutLocationState } }> & GlobalProps;

export default function CheckoutSuccessRoute({ isAuthenticating, ...other }: Props) {
  const { postgres } = useStaticQuery<PostgresData<Record<'products', Product[]>>>(graphql`
      query PurchaseProducts {
          postgres {
              products: productsList(orderBy: CREATED_AT_DESC) {
                  id
                  title
                  slug
                  url
                  cover {
                      childImageSharp {
                          gatsbyImageData(layout: CONSTRAINED)
                      }
                  }
                  category {
                      id
                      slug
                      description
                  }
                  latestRelease: productReleasesList(orderBy: CREATED_AT_DESC, first: 1) {
                      id
                      version
                      platforms
                      supports
                      changes
                      createdAt
                      files: productFilesByReleaseIdList(orderBy: [TARGET_ASC, NAME_DESC]) {
                          id
                          name
                          description
                          target
                          size
                          supports
                      }
                  }
              }
          }
      }
  `);
  const products = useEntities<Product>(postgres.products);
  const { isGuestFlow, orderId, isPaymentSkipped } = useCheckout(other);
  const { populateLibrary } = useLibrary();
  const { clearCart } = useCart();
  const { route, ACCOUNT } = useRoutes();
  const [isFetching, setIsFetching] = React.useState(true);
  const [purchase, setPurchase] = React.useState<API.FetchPurchase.Response['purchase']>({ ids: [] });

  React.useEffect(() => clearCart(), []);

  React.useEffect(() => {
    (async () => {
      try {
        const response = await api.fetchPurchase(orderId);
        populateLibrary(response.purchase);
        setPurchase(response.purchase);
      } catch (error) {
      } finally {
        setIsFetching(false);
      }
    })();
  }, [orderId]);

  if (!orderId) {
    return (
      <Wrapper useFixedWidth>
        <Message type="error">Invalid order number</Message>
      </Wrapper>
    );
  }

  return (
    <Main>
      <Centerer
        withTopBarOffset
        withVerticalPadding
      >
        <CartStepsIndicator
          activeStep="download"
          isAuthenticating={isAuthenticating}
          skipPayment={isPaymentSkipped}
          guestFlow={isGuestFlow}
        />
        <Content>
          {orderId && (
            <Info
              title="Thank you for purchase!"
              level={2}
            >
              <p>Order <b>#{orderId}</b> is successfully complete.</p>
              <p>You can access purchased products at any time in your account <Link to={route(ACCOUNT)}>library</Link>.</p>
            </Info>
          )}
          <div className={styles.grid}>
            <LibraryExplorer
              ids={purchase.ids}
              products={products}
              isLoading={isFetching}
            />
          </div>
        </Content>
      </Centerer>
    </Main>
  );
}
