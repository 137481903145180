import * as React from 'react';
import { graphql } from 'gatsby';
import { Router } from '@reach/router';
import { LABEL } from '@core/constants';
import { useEntities, useRoutes } from '@core/hooks';
import CheckoutResultLayout from '@layouts/CheckoutResultLayout';
import BillingRoute from '@routes/billing';
import ConfirmOrderRoute from '@routes/confirm_order';
import CheckoutSuccessRoute from '@routes/checkout_success';
import CheckoutFailureRoute from '@routes/checkout_failure';
import NotFoundRoute from '@pages/404';
import BaseHead from '@components/Head';
import { PostgresData, Product, Bundle } from '@interface/gatsby';
import { PageProps } from '@interface/common';


type DataType = PostgresData<Record<'products', Product[]>> & PostgresData<Record<'bundles', Bundle[]>>;

export default function CheckoutPage({ data, isAuthenticating, isFetchingCart }: PageProps<DataType>) {
  const products = useEntities<Product>(data.postgres.products);
  const bundles = useEntities<Bundle>(data.postgres.bundles);
  const routes = useRoutes();
  const layoutProps = { isAuthenticating, isFetchingCart };
  const routeProps = { ...layoutProps, products, bundles };

  return (
    // @ts-ignore
    <Router basepath={routes._CHECKOUT_BASE} primary={false} component={React.Fragment}>
      <NotFoundRoute default/>
      <CheckoutResultLayout {...layoutProps} path="/">
        <CheckoutSuccessRoute {...routeProps} path={routes.CHECKOUT_SUCCESS}/>
        <CheckoutFailureRoute {...routeProps} path={routes.CHECKOUT_FAILURE}/>
      </CheckoutResultLayout>
      <BillingRoute {...routeProps} path={routes.BILLING}/>
      <ConfirmOrderRoute {...routeProps} path={routes.CONFIRM_ORDER}/>
    </Router>
  );
}

export function Head({ params }: PageProps<DataType>) {
  const { clean, BILLING, CONFIRM_ORDER, CHECKOUT_SUCCESS, CHECKOUT_FAILURE } = useRoutes();

  let title;
  switch (params['*']) {
    case clean(BILLING):
      title = LABEL.BILLING;
      break;
    case clean(CONFIRM_ORDER):
      title = LABEL.CONFIRM_ORDER;
      break;
    case clean(CHECKOUT_SUCCESS):
      title = LABEL.CHECKOUT_SUCCESS;
      break;
    case clean(CHECKOUT_FAILURE):
      title = LABEL.CHECKOUT_FAILURE;
      break;
    default:
      title = LABEL.NOT_FOUND;
      break;
  }

  return <BaseHead title={title}/>;
}

export const allProductsQuery = graphql`
    query CheckoutProducts {
        postgres {
            products: productsList(orderBy: CREATED_AT_DESC) {
                id
                title
                slug
                url
                price
                cover {
                    childImageSharp {
                        gatsbyImageData(layout: CONSTRAINED)
                    }
                }
                category {
                    slug
                    description
                }
            }
            bundles: bundlesList(orderBy: CREATED_AT_DESC) {
                id
                title
                slug
                url
                price
                discount
                cover {
                    childImageSharp {
                        gatsbyImageData(layout: CONSTRAINED)
                    }
                }
            }
        }
    }
`;
