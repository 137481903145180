import * as React from 'react';
import { navigate } from 'gatsby';
import { STEP } from '@core/constants/cart';
import { useRoutes } from '@core/hooks';
import CartRoute from '@routes/cart';
import Spinner from '@components/Spinner';
import { RouteComponentProps } from '@reach/router';
import { AnyObject, GlobalProps } from '@interface/common';
import { Product, Bundle } from '@interface/gatsby';


type Props = RouteComponentProps & GlobalProps & {
  products: AnyObject<Product>;
  bundles: AnyObject<Bundle>
};

export default function ConfirmOrderRoute(props: Props) {
  const { route, BILLING } = useRoutes();
  // @ts-ignore
  const isGuest = props.location?.state?.isGuest;

  React.useEffect(() => {
    if (!isGuest) {
      // noinspection JSIgnoredPromiseFromCall
      navigate(route(BILLING), { replace: true });
    }
  }, []);

  if (!isGuest) {
    return <Spinner/>;
  }

  return (
    <CartRoute {...props} step={STEP.CONFIRM}/>
  );
}
