import * as React from 'react';
import { navigate } from 'gatsby';
import { useRecoilValue } from 'recoil';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import clsx from 'clsx';
import { cartStatsState } from '@core/atoms';
import { POSITION, LABEL } from '@core/constants';
import { STEP } from '@core/constants/cart';
import { useDisallowAuthRoutes, useCart, useRoutes, useUser, useMergeCartItems } from '@core/hooks';
import Spinner from '@components/Spinner';
import Main from '@components/Main';
import Centerer from '@components/Centerer';
import Sidebar from '@components/Sidebar';
import Content from '@components/Content';
import Title from '@components/Title';
import Message from '@components/Message';
import CartStepsIndicator from '@components/cart/CartStepsIndicator';
import CartSummary from '@components/cart/CartSummary';
import LogInForm from '@components/auth/LogInForm';
import SignUpForm from '@components/auth/SignUpForm';
import { FacebookIdentityButton, GoogleIdentityButton } from '@components/thirdparty';
import LockIcon from '@assets/lock.svg';
import { RouteComponentProps } from '@reach/router';
import { Product, Bundle } from '@interface/gatsby';
import { AnyObject, API, GlobalProps } from '@interface/common';
import * as styles from './billing.module.scss';


type Props = RouteComponentProps & GlobalProps & {
  products: AnyObject<Product>;
  bundles: AnyObject<Bundle>;
}

export default function BillingRoute({ isAuthenticating, products, bundles }: Props) {
  const cart = useRecoilValue(cartStatsState);
  const { signIn } = useUser();
  const { calculateTotals } = useCart();
  const { route, CONFIRM_ORDER } = useRoutes();

  const disallowAuthRoutes = useDisallowAuthRoutes(isAuthenticating);
  const nextStep = route(CONFIRM_ORDER);
  const items = useMergeCartItems(products, bundles);

  const { savedAmount, totalAmount } = React.useMemo(() => calculateTotals(products, bundles), [cart]);

  const handleSignIn = async (response: API.LogIn.Response) => {
    const isGuest = true;
    signIn(response, isGuest);
    await navigate(nextStep, { state: { isGuest }, replace: true });
  };

  if (disallowAuthRoutes || cart.isEmpty) {
    return <Spinner/>;
  }

  return (
    <Main>
      <Centerer
        className={styles.progress}
        withTopBarOffset
        withVerticalPadding
      >
        <CartStepsIndicator
          activeStep={STEP.BILLING}
          isAuthenticating={isAuthenticating}
          guestFlow
        />
      </Centerer>
      <Centerer
        withVerticalPadding
        className={styles.centerer}
        sidebar={POSITION.RIGHT}
      >
        <Content className={styles.content}>
          <Title level={2} className={styles.content__title}>{LABEL.BILLING}</Title>
          <Tabs
            className={styles.tabs}
            disableUpDownKeys
            forceRenderTabPanel
          >
            <TabList className={clsx(styles.constrained, styles.tabs__list)}>
              <Tab className={styles.tabs__tab}>
                <span>Returning customer?</span>
              </Tab>
              <Tab className={styles.tabs__tab}>
                <span>Don't have an account yet?</span>
              </Tab>
            </TabList>
            <TabPanel className={styles.login}>
              <Title level={2} className={styles.login__title}>Returning customer?</Title>
              <LogInForm
                className={clsx(styles.constrained, styles.login__content)}
                redirect={nextStep}
                isGuest
              />
              <div className={clsx(styles.constrained, styles.login__message)}>
                <Title level={2}>Why register?</Title>
                <p>
                  We sell digital products, and registration of a customer account is required to provide you access to
                  download files of purchased products, as well as access to possible future updates.
                </p>
              </div>
            </TabPanel>
            <TabPanel className={styles.register}>
              <Title level={2} className={styles.register__title}>Don't have an account yet?</Title>
              <div className={styles.constrained}>
                <SignUpForm
                  redirect={nextStep}
                  isGuest
                />
              </div>
            </TabPanel>
          </Tabs>
        </Content>
        <Sidebar
          className={styles.sidebar}
          sticky={false}
        >
          <CartSummary
            totalAmount={totalAmount}
            savedAmount={savedAmount}
            items={items}
            hasBundles={cart.hasBundles}
            hideCoupon
          />
          <div className={styles.sidebar__social}>
            <Title level={2}>Rather go</Title>
            <div className={styles.sidebar__social__content}>
              <GoogleIdentityButton
                text="continue"
                overwriteCart
                onSuccess={handleSignIn}
              />
              <FacebookIdentityButton
                label={LABEL.CONTINUE}
                overwriteCart
                onSuccess={handleSignIn}
              />
            </div>
          </div>
          <Message
            className={clsx('form--constrained', styles.sidebar__message)}
            type="hint"
            icon={LockIcon}
          >
            All data is transmitted<br/> encrypted via a secure TLS connection.
          </Message>
        </Sidebar>
      </Centerer>
    </Main>
  );
}
