import * as React from 'react';
import { STEP } from '@core/constants/cart';
import { useRoutes } from '@core/hooks';
import useCheckout, { CheckoutLocationState } from '@core/hooks/useCheckout';
import Main from '@components/Main';
import Centerer from '@components/Centerer';
import Content from '@components/Content';
import Info from '@components/Info';
import Message from '@components/Message';
import Wrapper from '@components/Wrapper';
import CartStepsIndicator from '@components/cart/CartStepsIndicator';
import { RouteComponentProps } from '@reach/router';
import { Product } from '@interface/gatsby';
import { AnyObject, GlobalProps } from '@interface/common';


type Props = RouteComponentProps<{ location: { state: CheckoutLocationState } }> & GlobalProps & {
  products: AnyObject<Product>;
}

export default function CheckoutFailureRoute({ isAuthenticating, ...other }: Props) {
  const { isGuestFlow, orderId } = useCheckout(other);
  const { route, CART } = useRoutes();

  if (!orderId) {
    return (
      <Wrapper useFixedWidth>
        <Message type="error">Invalid order number</Message>
      </Wrapper>
    );
  }

  return (
    <Main>
      <Centerer
        withTopBarOffset
        withVerticalPadding
      >
        <CartStepsIndicator
          activeStep={STEP.PAYMENT}
          isAuthenticating={isAuthenticating}
          guestFlow={isGuestFlow}
          hasError
        />
        <Content>
          <Info
            title="Order is canceled."
            level={2}
            linkUrl={route(CART)}
            linkText="Back to cart"
          >
            <p>Payment for order <b>#{orderId}</b> has not been confirmed.</p>
            <p>If you want to retry the process, please begin from cart again.</p>
          </Info>
        </Content>
      </Centerer>
    </Main>
  );
}
